.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.subpage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  color: white;
}

.data-table {
  border-collapse: collapse;
  width: 80%;
  margin: 20px 0;
}

.data-table th,
.data-table td {
  border: 1px solid green;
  padding: 8px;
  text-align: center;
}

.data-table th {
  background-color: green;
  color: white;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  color: black;
}

.login-container h2 {
  color: green;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid green;
  border-radius: 10px;
  padding: 20px;
}

.login-container label {
  margin: 10px 0 5px;
  color: green;
}

.login-container input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid green;
  border-radius: 5px;
}

.login-container button {
  padding: 10px 20px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: darkgreen;
}
.training-detail-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}
